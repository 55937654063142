// Theirs ---------------------------------------------------------------------
import React from "react";
import { useMachine } from "@xstate/react";
import bannersMachine from "../../machines/bannersMachine";
import protectedApi from "../../auth/protectedApi";
import Banner from "./Banner";
// Ours -----------------------------------------------------------------------

// Types ----------------------------------------------------------------------
// Local ----------------------------------------------------------------------
async function getBanners() {
  try {
    const { data } = await protectedApi.get("banners");

    return {
      banners: data.banners,
    };
  } catch {
    return {
      banners: [],
    };
  }
}

// Component ------------------------------------------------------------------
const Banners: React.FC = () => {
  const [state] = useMachine(bannersMachine, {
    services: {
      fetchBanners: async (_context, _event) => getBanners(),
    },
  });

  const { banners } = state.context;

  if (!Array.isArray(banners) || (Array.isArray(banners) && banners.length === 0)) {
    return <></>;
  }

  return (
    <div className="pointer-events-none">
      {banners.map((banner, index) => {
        if (banner.type === "EXPIRING_LICENSE") {
          return (
            <Banner key={`banner-${index}-${banner.type}`} type="warning">
              {banner.data.licenseType === "TRIAL" ? "Your trial license will expire on " : "Your license will expire on "}
              {new Date(banner.data.licenseEndDate).toLocaleDateString()}
            </Banner>
          );
        }

        if (banner.type === "EXPIRED_LICENSE") {
          return (
            <Banner key={`banner-${index}-${banner.type}`} type="error">
              {banner.data.licenseType === "TRIAL" ? "Your trial license expired on " : "Your license expired on "}
              {new Date(banner.data.licenseEndDate).toLocaleDateString()}
            </Banner>
          );
        }

        if (banner.type === "UPCOMING_MAINTENANCE") {
          const start = new Date(banner.data.start);
          const finish = new Date(banner.data.finish);

          const details = banner?.data?.details;

          return (
            <Banner key={`banner-${index}-${banner.type}`} type="warning">
              <div className="ml-3">
                <div className={`text-sm`}>
                  <span>Upcoming Maintenance:</span>
                  <b>
                    {start.toLocaleDateString()} {start.toLocaleTimeString()} - {finish.toLocaleDateString()} {finish.toLocaleTimeString()}
                  </b>
                </div>
                {details && (
                  <div className={`mt-2 text-sm `}>
                    <div className="whitespace-pre-line max-h-24 overflow-y-auto">{details}</div>
                  </div>
                )}
              </div>
            </Banner>
          );
        }

        if (banner.type === "ACTIVE_MAINTENANCE") {
          const start = new Date(banner.data.start);
          const finish = new Date(banner.data.finish);

          const details = banner?.data?.details;

          return (
            <Banner key={`banner-${index}-${banner.type}`} type="error">
              <div className="ml-3">
                <div className={`text-sm`}>
                  <span>Instance in Maintenance mode: </span>
                  <b>
                    {start.toLocaleDateString()} {start.toLocaleTimeString()} - {finish.toLocaleDateString()} {finish.toLocaleTimeString()}
                  </b>
                </div>
                {details && (
                  <div className={`mt-2 text-sm `}>
                    <div className="whitespace-pre-line max-h-24 overflow-y-auto">{details}</div>
                  </div>
                )}
              </div>
            </Banner>
          );
        }

        return <></>;
      })}
    </div>
  );
};

export default Banners;
