const theme = {
  input: {
    radio:
      " focus:ring-primary-300-important focus:outline-transparent focus:outline-2 focus:outline-offset-2 focus:border-primary-500 focus:ring  focus:disabled:shadow-none focus:disabled:border-[#6b7280] disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-disabled disabled:checked:bg-current disabled:not(:checked):bg-current text-primary-600",
    checkbox:
      "rounded focus:outline-transparent focus:outline-2 focus:outline-offset-2 focus:border-primary-500 focus:ring focus:ring-primary-300-important focus:disabled:shadow-none focus:disabled:border-[#6b7280] disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-disabled disabled:checked:bg-current disabled:not(:checked):bg-current text-primary-600",
  },
};

export default theme;
