import React from "react";

const BuildInfo: React.FC = () => {
  const { REACT_APP_BUILD_VER, REACT_APP_BUILD_DESC, REACT_APP_BUILD_COMMIT, REACT_APP_BUILD_BRANCH } = process.env;

  if (REACT_APP_BUILD_VER) {
    return <span className="font-semibold">v{REACT_APP_BUILD_VER}</span>;
  }

  return (
    <span>
      Deployed from <span className="font-semibold">{REACT_APP_BUILD_BRANCH}</span> commit <span className="font-semibold">{REACT_APP_BUILD_COMMIT}</span> via
      build <span className="font-semibold">{REACT_APP_BUILD_DESC}</span>
    </span>
  );
};

export default BuildInfo;
