import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./App.css";
import SuspensePage from "./pages/SuspensePage";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./auth/authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent
// Adjust as needed for different use cases
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

ReactDOM.render(
  <Suspense fallback={<SuspensePage />}>
    <App msalInstance={msalInstance} />
  </Suspense>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
