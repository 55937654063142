import React, { useEffect, useContext, lazy } from "react";
import { Route, useLocation } from "react-router-dom";
import { useSelector } from "@xstate/react";
import Layout from "../../layouts/Layout";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest, ROLES } from "../../auth/authConfig";
import { AuthenticationMachineContext, authenticatingSelector, authenticatedSelector, unauthenticatedSelector, accountSelector } from "../../auth/authContext";
import { SpinnerGap } from "phosphor-react";
const Page403 = lazy(() => import("../../pages/403"));

type ProtectedRouteProperties = {
  component: React.FC<any>;
  roles: string[] | ROLES[];
  path: string;
  exact?: boolean;
};

export const ProtectedRoute: React.FC<ProtectedRouteProperties> = ({ component: Component, roles, ...properties }) => {
  const { login } = useMsalAuthentication(InteractionType.Redirect, loginRequest);
  const { inProgress, accounts } = useMsal();
  const authenticationMachine = useContext(AuthenticationMachineContext);
  const authenticating = useSelector(authenticationMachine as any, authenticatingSelector);
  const authenticated = useSelector(authenticationMachine as any, authenticatedSelector);
  const unauthenticated = useSelector(authenticationMachine as any, unauthenticatedSelector);
  const account = useSelector(authenticationMachine as any, accountSelector);
  const location = useLocation();

  useEffect(() => {
    if (authenticating === false && unauthenticated && accounts.length === 0) {
      sessionStorage.setItem("redirect", location.pathname);
      login();
    }
  }, [accounts.length, authenticating, inProgress, login, unauthenticated, location]);

  if (authenticating) {
    return (
      <div className="w-full h-screen flex justify-center items-center ">
        <div className="flex items-center">
          <SpinnerGap size={36} className="animate-spin" /> <span className="ml-4">Verifying authentication...</span>
        </div>
      </div>
    );
  }

  if (authenticated) {
    if (roles.length > 0) {
      const intersection = roles.filter((role) => account?.idTokenClaims?.roles?.includes(role));
      if (intersection.length === 0) {
        return (
          <Layout>
            <Page403 />
          </Layout>
        );
      }
    }

    return <Route {...properties} render={(routeProperties) => <Component {...routeProperties} />} />;
  }

  return null;
};
