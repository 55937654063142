import React from "react";
import { SpinnerGap } from "phosphor-react";

const SuspensePage: React.FC = () => (
  <div className="w-full h-screen flex justify-center items-center ">
    <div className="flex items-center">
      <SpinnerGap size={36} className="animate-spin" /> <span className="ml-4">Loading CI Synchronizer...</span>
    </div>
  </div>
);

export default SuspensePage;
