import { assign, createMachine } from "xstate";
import { AccountInfo } from "@azure/msal-common";
import protectedApi from "../auth/protectedApi";
export type AuthenticationMachineContext = {
  account: AccountInfo | undefined;
  error: Error | undefined;
};

export type AuthenticationMachineEvent =
  | {
      type: "REPORT_IS_UNAUTHENTICATED";
      error: Error | undefined;
    }
  | {
      type: "REPORT_IS_AUTHENTICATED";
      account: AccountInfo;
    }
  | {
      type: "LOG_OUT";
    }
  | {
      type: "CHECK_AUTHENTICATION";
    }
  | {
      type: "LOG_IN";
      account: AccountInfo;
    }
  | {
      type: "UPDATE_ERROR";
      error: Error;
    };

const authenticationMachine = createMachine<AuthenticationMachineContext>(
  {
    id: "authentication",
    initial: "checkingAuthentication",
    on: {
      CHECK_AUTHENTICATION: ".checkingAuthentication",
      UPDATE_ERROR: {
        actions: "setAuthError",
      },
    },
    states: {
      checkingAuthentication: {
        entry: ["clearError", "checkAuthentication"],
        on: {
          REPORT_IS_AUTHENTICATED: [
            {
              target: "authenticated.validTenant",
              actions: "assignUserDetailsToContext",
              cond: "isValidTenant",
            },
            {
              target: "authenticated.invalidTenant",
              actions: "assignUserDetailsToContext",
            },
          ],
          REPORT_IS_UNAUTHENTICATED: {
            target: "unauthenticated",
            actions: "setAuthError",
          },
        },
      },
      authenticated: {
        states: {
          validTenant: {},
          invalidTenant: {
            entry: ["pushTo403"],
          },
        },
      },
      unauthenticated: {
        entry: ["clearUserDetailsFromContext", "logout"],
        on: {
          LOG_IN: {
            target: "authenticated",
            actions: "assignUserDetailsToContext",
          },
        },
      },
    },
  },
  {
    guards: {
      // @ts-ignore
      isValidTenant: (_context, event) => event.account?.tenantId === process.env.REACT_APP_AUTH_CUST_TENANT_ID,
    },
    actions: {
      assignUserDetailsToContext: assign((_context, event) => {
        if (event.type !== "REPORT_IS_AUTHENTICATED") {
          return {};
        }

        return {
          error: undefined,
          account: event.account,
        };
      }),
      clearError: assign((_context, _event) => ({
        error: undefined,
      })),
      clearUserDetailsFromContext: assign((_context, _event) => {
        protectedApi.defaults.headers.common.Authorization = "";
        return {
          account: undefined,
        };
      }),
      setAuthError: assign((_context, event) => {
        if (event.type === "UPDATE_ERROR") {
          return {
            error: event.error,
          };
        }

        return {};
      }),
    },
  },
);

export default authenticationMachine;
