// Theirs ---------------------------------------------------------------------
import React from "react";
import { CheckCircle, WarningCircle, XCircle } from "phosphor-react";
import classNames from "classnames";
// Ours -----------------------------------------------------------------------

// Types ----------------------------------------------------------------------

type IBanner = {
  type: "success" | "warning" | "error";
};

// Local ----------------------------------------------------------------------
// Component ------------------------------------------------------------------
const Banner: React.FC<IBanner> = ({ type, children }) => {
  const isSuccess = type === "success";
  const isWarning = type === "warning";
  const isError = type === "error";

  return (
    <div
      className={classNames([
        "pointer-events-auto flex items-center justify-start gap-x-3 shadow px-6 py-2.5 sm:rounded sm:py-3 sm:pl-4 sm:pr-3.5 mb-4",
        {
          "text-yellow-900 bg-yellow-200": isWarning,
          "text-red-900 bg-red-200": isError,
          "text-green-900 bg-green-400": isSuccess,
        },
      ])}
    >
      {isSuccess && <CheckCircle />}
      {isWarning && <WarningCircle />}
      {isError && <XCircle />}
      <div>{children}</div>
    </div>
  );
};

export default Banner;
