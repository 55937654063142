import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useInterpret } from "@xstate/react";
import authenticationMachine, { AuthenticationMachineEvent, AuthenticationMachineContext as authMachineContext } from "../machines/authenticationMachine";
import { Actor, State } from "xstate";
import { useMsal } from "@azure/msal-react";
import { AuthenticationResult, EventMessage, EventType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";

export const AuthenticationMachineContext = React.createContext<Actor<any, any> | undefined>(undefined);

export const authenticatingSelector = (state: State<authMachineContext, AuthenticationMachineEvent>): boolean => state.matches("checkingAuthentication");
export const authenticatedSelector = (state: State<authMachineContext, AuthenticationMachineEvent>): boolean => state.matches("authenticated");
export const validTenantSelector = (state: State<authMachineContext, AuthenticationMachineEvent>): boolean => state.matches("authenticated.validTenant");
export const invalidTenantSelector = (state: State<authMachineContext, AuthenticationMachineEvent>): boolean => state.matches("authenticated.invalidTenant");
export const unauthenticatedSelector = (state: State<authMachineContext, AuthenticationMachineEvent>): boolean => state.matches("unauthenticated");
export const accountSelector = (state: State<authMachineContext, AuthenticationMachineEvent>): any => state.context.account;
export const errorSelector = (state: State<authMachineContext, AuthenticationMachineEvent>): any => state.context.error;

const AuthenticationMachineProvider: React.FC = ({ children }) => {
  const { instance, accounts, inProgress } = useMsal();
  const history = useHistory();

  const authService = useInterpret(authenticationMachine, {
    actions: {
      checkAuthentication: async () => {
        try {
          const currentAccount = instance.getActiveAccount();
          if (currentAccount && currentAccount.idTokenClaims) {
            try {
              await instance.acquireTokenSilent({
                scopes: loginRequest.scopes,
              });
              authService.send("REPORT_IS_AUTHENTICATED", { account: currentAccount });
            } catch {
              instance.loginRedirect(loginRequest);
            }
          } else {
            authService.send("REPORT_IS_UNAUTHENTICATED");
          }
        } catch (error) {
          authService.send("REPORT_IS_UNAUTHENTICATED", {
            error,
          });
        }
      },
      pushTo403: () => {
        history.push("/403");
      },
    },
  });

  useEffect(() => {
    async function handleRedirect() {
      try {
        const tokenResponse = await instance.handleRedirectPromise();
        if (tokenResponse && tokenResponse.tenantId !== process.env.REACT_APP_AUTH_CUST_TENANT_ID) {
          throw new Error("tids don't match");
        }
      } catch (error) {
        authService.send("UPDATE_ERROR", {
          error,
        });
      }
    }

    handleRedirect();
  }, [authService, instance]);

  useEffect(() => {
    const id = instance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        instance.setActiveAccount(account);
      } else if (event.eventType === EventType.LOGIN_FAILURE) {
        authService.send("UPDATE_ERROR", {
          error: event.error,
        });
      }
    });

    return () => {
      instance.removeEventCallback(id as string);
    };
  }, [authService, instance]);

  useEffect(() => {
    authService.send("CHECK_AUTHENTICATION");
  }, [instance, inProgress, accounts, authService]);

  return <AuthenticationMachineContext.Provider value={authService}>{children}</AuthenticationMachineContext.Provider>;
};

export default AuthenticationMachineProvider;
