import React, { lazy } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { browserHistory } from "./appinsights";
import { ProtectedRoute } from "./components/route/ProtectedRoute";
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { CustomNavigationClient, ROLES } from "./auth/authConfig";
import AuthenticationMachineProvider from "./auth/authContext";
import Authenticating from "./pages/Authenticating";
import { Windmill } from "@windmill/react-ui";
import theme from "./theme";

const ProtectedApp = lazy(() => import("./ProtectedApp"));
const Login = lazy(() => import("./pages/Login"));
const Page403 = lazy(() => import("./pages/403"));
const Maintenance = lazy(() => import("./pages/Maintenance"));

type AppProperties = {
  msalInstance: IPublicClientApplication;
};

const App: React.FC<AppProperties> = ({ msalInstance }) => {
  msalInstance.setNavigationClient(new CustomNavigationClient(browserHistory));

  // Push metrics at regular intervals

  return (
    <Windmill theme={theme}>
      <Router history={browserHistory}>
        <MsalProvider instance={msalInstance}>
          <AuthenticationMachineProvider>
            <Switch>
              <Route path="/authenticating" component={Authenticating} />
              <Route path="/login" component={Login} />
              <Route path="/403" component={Page403} />
              <Route path="/maintenance" component={Maintenance} />
              <ProtectedRoute path="/app" component={ProtectedApp} roles={[ROLES.ADMIN, ROLES.READER, ROLES.SCHEDULER]} />
              <Redirect from="/" to="/login" />
            </Switch>
          </AuthenticationMachineProvider>
        </MsalProvider>
      </Router>
    </Windmill>
  );
};

export default App;
